.app-panel {
    background-color: #FFFFFF;


    border-radius: 8px;
    max-width: 500px;
    height:200px;

    padding:20px;

    -webkit-box-shadow: 0 3px 5px #bebebe;
	-moz-box-shadow: 0 3px 5px #bebebe;
	box-shadow: 0 3px 5px #bebebe;
}


.icon-container {
    display:flex;
    justify-content: center;
    align-items: center;

    width:40px;
    height:40px;

    border-radius: 6px;
    

    background-color: #EEEEEE;
}